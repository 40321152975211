import React from 'react';

import Chatbot from "./components/Chatbot";
import styles from './App.module.css';

function App() {
  return (
    <main className={styles.App}>
      <Chatbot />
    </main>
  );
}

export default App;
