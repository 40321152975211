import { Configuration, getClient, Webchat, WebchatProvider } from '@botpress/webchat';
import { buildTheme } from "@botpress/webchat-generator";
import React from 'react';
import './index.css';

const CLIENT_ID = '78dbe248-9a5e-434a-8302-c3473ed089a0';
// const BOT_ID = 'e3fc3377-0b10-46e3-a972-9427adfeaaba';

const Chatbot: React.FC<{}> = () => {
  const client = getClient({ clientId: CLIENT_ID });

  const config: Configuration = {
    composerPlaceholder: "Ask me anything...",
    botName: "The Patient Centre",
    botAvatar: "doctor.png",
  };

  const { theme, style } = buildTheme({
    themeName: "prism",
    themeColor: "#634433",
  });

  return <>
    <style>{style}</style>
    <WebchatProvider client={client} configuration={config} theme={theme}>
      <Webchat />
    </WebchatProvider>
  </>;
}

export default Chatbot;
